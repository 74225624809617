.index-content {
  margin-top: 20px;
}

.framed {
  border: 1px solid var(--accent);
  padding: 20px;

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
}

.posts {
  width: 100%;
}

.post {
  width: 100%;
  text-align: left;
  margin: 20px auto;
  padding: 20px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--border-color);
  }

  &-meta {
    font-size: 1rem;
    margin-bottom: 10px;
    color: color-mix(in srgb, var(--accent), transparent 30%);;

    & > *:not(:first-child) {
      &::before {
        content: "::";
        display: inline-block;
        margin: 0 8px;
      }
    }
  }

  &-title {
    --border: 3px dotted var(--accent);
    position: relative;
    color: var(--accent);
    margin: 0 0 15px;
    padding-bottom: 15px;
    border-bottom: var(--border);

    &:after {
      content: '';
      position: absolute;
      bottom: 2px;
      display: block;
      width: 100%;
      border-bottom: var(--border);
    }

    a {
      text-decoration: none;
    }
  }

  &-tags {
    display: block;
    margin-bottom: 20px;
    font-size: 1rem;
    opacity: .5;

    a {
      text-decoration: none;
    }
  }

  &-content {
    margin-top: 30px;
  }

  &-cover {
    border: 20px solid var(--accent);
    background: transparent;
    margin: 40px 0;
    padding: 20px;

    @media (var(--phone)) {
      padding: 10px;
      border-width: 10px;
    }
  }

  ul {
    list-style: none;

    li:not(:empty):before {
      content: '-';
      position: absolute;
      left: -20px;
      color: var(--accent);
    }
  }
}

.post--regulation {
  h1 {
    justify-content: center;
  }

  h2 {
    justify-content: center;
    margin-bottom: 10px;

    & + h2 {
      margin-top: -10px;
      margin-bottom: 20px;
    }
  }
}

.hanchor {
  color: color-mix(in srgb, var(--accent), transparent 10%);
  text-decoration: none;
  margin-left: 10px;
  visibility: hidden;
}

h1:hover a, h2:hover a, h3:hover a, h4:hover a {
  visibility: visible;
}

.footnotes {
  color: color-mix(in srgb, var(--accent), transparent 50%);
}

figure:has(.post-cover) {
  margin: 40px 0;


  & > .post-cover {
    margin: 0;
  }

  & > figcaption {
    margin-top: 0;
  }
}